:root {
  --primary: #000000;
  --secondary: #8E56F9;
  --white: #FFFFFF;
  --grey-light: #cfcfcf;
  --grey: #252525;
  --primary-main: rgba(0,0,0,.69);
}


*, *::after, *::before {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
  font-family: "GT Walsheim Pro Regular Regular";
}


body, html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  background-color: var(--primary);
}